import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Video from 'components/video.tsx';

const Demo = () => (
  <Layout>
    <SEO title="Maistro Demo" />

    <div className="bg-stone py-12">
      <div className="container">
        <div className="grid grid-cols-1 xl:grid-cols-2 items-center gap-6 p-6">
          <div className="flex gap-4 flex-col ">
            <h1 className="block font-bold ">
              Maistro platform eSourcing demo
            </h1>
            <h3 className="normal-case">Delivering autonomy with control</h3>

            <div className="text-primary normal-case font-normal">
              Watch how the Maistro platform allows any user in an organisation
              to quickly and easily tender for any service, no matter the size
              or value, with grip and control built-in.
            </div>
          </div>

          <Video
            title="Maistro demo video"
            src="https://www.youtube.com/embed/TkAPOLXXdqI"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default Demo;
