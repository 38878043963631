import React from 'react';

interface VideoProps {
  src: string;
  title: string;
}

const Video = ({ src, title }: VideoProps) => (
  <div className="relative w-full" style={{ minHeight: 300 }}>
    <iframe
      src={src}
      title={title}
      className="absolute top-0 left-0 w-full h-full"
      frameBorder="0"
      allowFullScreen
    />
  </div>
);

export default Video;
